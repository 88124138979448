import {
  AirportExtended,
  AvailableAdditionalLuggageRequest,
  MainDictionary,
} from '@app-core/entities';

export class GetAnnouncements {
  public static readonly type = '[Resources] Get Announcements';
}
export class GetAirports {
  public static readonly type = '[Resources] Get airports';
}
export class GetCountries {
  public static readonly type = '[Resources] Get countries';
}
export class GetCities {
  public static readonly type = '[Resources] Get cities';
}
export class GetNationalities {
  public static readonly type = '[Resources] Get nationalities';
}
export class GetResources {
  public static readonly type = '[Resources] Get all items';
}
export class GetResourcesSuccess {
  public static readonly type = '[Resources] Get all items success';
  constructor(public readonly payload: MainDictionary, public readonly groupedAirports: AirportExtended[]) {}
}
export class GetAvailableAdditionalLuggage {
  public static readonly type = '[Resources] Get Available Additional Luggage';
  constructor(public readonly payload: AvailableAdditionalLuggageRequest) {}
}

