import {BaseModel} from "@app-core/models";

export enum AdditionalServiceTypes {
  Luggage = 1,
  // Service = 2,
}
export enum AdditionalServiceDirectionTypes {
  Direct = 1,
  Back = 2,
}
export const AdditionalServiceTypesArray: BaseModel<AdditionalServiceTypes,string>[] = [
  { value: AdditionalServiceTypes.Luggage, label: 'Luggage' },
  // { id: AdditionalServiceTypes.Service, name: 'Service' },
];
