import { Injectable } from '@angular/core';
import {
  Airport,
  AllowedDirection,
  AvailableAdditionalLuggageRequest,
  City,
  Country,
  Nationality,
  PriceAdjustedDefaultAdditionalLuggage,
  PriceAdjustedDefaultAdditionalLuggageExtended,
} from '@app-core/entities';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Actions, ofActionSuccessful, Select } from '@ngxs/store';
import {Observable, shareReplay, startWith} from 'rxjs';
import {
  GetAnnouncements,
  GetAvailableAdditionalLuggage,
  GetResources,
  ResourcesState,
} from './resources';
import { ResourcesStateModel } from './resources/resources-state.model';
import { combineLatest, map } from 'rxjs';
import { AdditionalServiceDirectionTypes } from '@app-core/constants/additional-service';
import {Announcement} from "@app-core/models";

@Injectable()
export class ResourcesStateService {
  constructor(private readonly actions$: Actions) {}
  @Select(ResourcesState.airports)
  public readonly airports$!: Observable<Airport[]>;
  @Select(ResourcesState.countries)
  public readonly countries$!: Observable<Country[]>;
  @Select(ResourcesState.cities)
  public readonly cities$!: Observable<City[]>;
  @Select(ResourcesState.nationalities)
  public readonly nationalities$!: Observable<Nationality[]>;
  @Select(ResourcesState.state)
  public readonly state$!: Observable<ResourcesStateModel>;
  @Select(ResourcesState.allowedDictionaries)
  public readonly allowedDictionaries$!: Observable<AllowedDirection[]>;
  @Select(ResourcesState.announcements)
  public readonly announcements$!: Observable<Announcement[]>;
  @Select(ResourcesState.availableLuggage)
  public readonly availableLuggage$!: Observable<PriceAdjustedDefaultAdditionalLuggage[]>;

  availableAdditionalLuggage$: Observable<
    PriceAdjustedDefaultAdditionalLuggageExtended[] | null
  > = combineLatest([
    this.state$,
    this.availableLuggage$
  ]).pipe(
    map(([resources, payload]) => {
      const { aircompanies } = resources;
      if (!payload) {
        return null;
      }
      return payload?.map((luggage) => {
        return {
          ...luggage,
          directionName:
            luggage.direction === AdditionalServiceDirectionTypes.Direct
              ? 'Direct'
              : 'Return',
          airlineName:
            aircompanies.find((a) => a.id === luggage.airlineId)?.name ?? '',
        };
      });
    })
  );
  @Dispatch() public getCommonData = () => new GetResources();
  @Dispatch() public getAnnouncements = () => new GetAnnouncements();
  @Dispatch() public getAvailableAdditionalLuggage = (
    parameters: AvailableAdditionalLuggageRequest
  ) => new GetAvailableAdditionalLuggage(parameters);
}
