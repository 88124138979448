import {
  Aircompany,
  Airport,
  AirportExtended,
  AllowedDirection,
  City,
  Country, Currency,
  Nationality,
  PriceAdjustedDefaultAdditionalLuggage,
} from '@app-core/entities';
import {Announcement} from "@app-core/models";

export interface ResourcesStateModel {
  airports: Airport[];
  countries: Country[];
  cities: City[];
  currencies: Currency[];
  nationality: Nationality[];
  allowedDirections: AllowedDirection[];
  aircompanies: Aircompany[];
  announcements: Announcement[];
  groupedAirports: AirportExtended[];
  nameInverted: boolean;
  availableLuggage: PriceAdjustedDefaultAdditionalLuggage[];
}

export const DEFAULT_RESOURCES_STATE_MODEL: ResourcesStateModel = {
  airports: [],
  countries: [],
  cities: [],
  currencies: [],
  nationality: [],
  allowedDirections: [],
  aircompanies: [],
  announcements: [],
  groupedAirports: [],
  nameInverted: false,
  availableLuggage: [],
  // announcements: [{
  //   id: 1,
  //   text: 'Тест 1',
  //   enText: 'Test 1',
  //   theme: AnnouncementTheme.Error,
  //   showOrder: 1
  // },{
  //   id: 2,
  //   text: 'Тест 2',
  //   enText: 'Test 2',
  //   theme: AnnouncementTheme.Warning,
  //   showOrder: 3
  // },{
  //   id: 3,
  //   text: 'Тест 3',
  //   enText: 'Test 3',
  //   theme: AnnouncementTheme.Success,
  //   showOrder: 2
  // }]
};
