import {Inject, Injectable} from '@angular/core';
import {
  Airport,
  AirportExtended,
  AllowedDirection,
  City,
  Country, Currency,
  Nationality, PriceAdjustedDefaultAdditionalLuggage,
} from '@app-core/entities';
import { AnnouncementTheme, SortingOrder } from '@app-core/enums';
import { Announcement } from '@app-core/models';
import {AnnouncementService, OrdersApiService} from '@app-core/api-services';
import {
  ChangeLanguage,
  ChangeLanguageSuccess,
  LayoutState,
} from '@app-states/layout';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { sort } from '@shared/utils';
import { switchMap, tap } from 'rxjs/operators';
import { sortAirportsFunc } from '../../search-page/search-block/utils';
import {
  DEFAULT_RESOURCES_STATE_MODEL,
  ResourcesStateModel,
} from './resources-state.model';
import {
  GetAnnouncements, GetAvailableAdditionalLuggage,
  GetResources,
  GetResourcesSuccess,
} from './resources.actions';
import {IResourcesService, IS_FULL_VERSION_TOKEN, RESOURCES_SERVICE} from "@app-core/constants";

@State<ResourcesStateModel>({
  name: 'resources',
  defaults: DEFAULT_RESOURCES_STATE_MODEL,
})
@Injectable()
export class ResourcesState {
  @Selector()
  public static airports(state: ResourcesStateModel): Airport[] {
    return state.airports;
  }
  @Selector()
  public static groupedAirports(state: ResourcesStateModel): AirportExtended[] {
    return state.groupedAirports;
  }
  @Selector()
  public static countries(state: ResourcesStateModel): Country[] {
    return state.countries;
  }
  @Selector()
  public static currencies(state: ResourcesStateModel): Currency[] {
    return state.currencies;
  }
  @Selector()
  public static cities(state: ResourcesStateModel): City[] {
    return state.cities;
  }
  @Selector()
  public static nationalities(state: ResourcesStateModel): Nationality[] {
    return state.nationality;
  }
  @Selector()
  public static availableLuggage(state: ResourcesStateModel): PriceAdjustedDefaultAdditionalLuggage[] {
    return state.availableLuggage;
  }
  @Selector()
  public static allowedDictionaries(
    state: ResourcesStateModel
  ): AllowedDirection[] {
    return state.allowedDirections;
  }
  // FIXME щитаю что костыль, но кусками почему-то не видит изменения в search провайдере через combineLatest
  @Selector()
  public static state(state: ResourcesStateModel): ResourcesStateModel {
    return state;
  }
  @Selector()
  public static announcements(state: ResourcesStateModel): Announcement[] {
    return state.announcements;
  }

  constructor(
    private readonly announcementApiService: AnnouncementService,
    private readonly ordersApiService: OrdersApiService,
    private readonly store: Store,
    @Inject(IS_FULL_VERSION_TOKEN) private isFullToken: boolean,
    @Inject(RESOURCES_SERVICE) private requestService: IResourcesService,
  ) {}

  @Action(GetResources)
  public getResources({
    setState,
    dispatch,
    patchState,
  }: StateContext<ResourcesStateModel>) {
    return this.requestService.getAllDictionaries().pipe(
      tap((val) => {
        const language = this.store.selectSnapshot(LayoutState.language);
        const {
          airports,
          countries,
          cities,
          currencies,
          nationalities,
          allowedDirections,
          aircompanies,
        } = val;
        // return sort(result, { field: 'label', order: SortingOrder.Asc });
        const normalize = () => {
          if (language && language.locale === 'ru') {
            const result = this.groupedAirportsFactory(
              airports,
              countries,
              cities,
              allowedDirections
            );
            return {
              newAirports: airports,
              newCountries: countries,
              newCities: cities,
              groupedAirports: result,
            };
          }
          return this.invert(airports, countries, cities, allowedDirections);
        };
        const normalized = normalize();
        patchState({
          cities: normalized.newCities,
          airports: normalized.newAirports,
          countries: normalized.newCountries,
          nationality: nationalities.filter(val=>!val.isDeleted),
          allowedDirections,
          aircompanies,
          currencies,
          groupedAirports: normalized.groupedAirports,
        });
        return dispatch(
          new GetResourcesSuccess(val, normalized.groupedAirports)
        );
      })
    );
  }
  @Action(ChangeLanguage)
  public changeLanguage(
    { dispatch, patchState, getState }: StateContext<ResourcesStateModel>,
    { language }: ChangeLanguage
  ) {
    const {
      airports,
      countries,
      cities,
      allowedDirections,
      nameInverted,
    } = getState();

    const invertedValue = this.invert(
      airports,
      countries,
      cities,
      allowedDirections
    );
    if (language && language.locale !== 'ru' && !nameInverted) {
      patchState({
        airports: invertedValue.newAirports,
        cities: invertedValue.newCities,
        countries: invertedValue.newCountries,
        nameInverted: true,
        groupedAirports: invertedValue.groupedAirports,
      });
    } else if (language && language.locale === 'ru' && nameInverted) {
      patchState({
        airports: invertedValue.newAirports,
        cities: invertedValue.newCities,
        countries: invertedValue.newCountries,
        nameInverted: false,
        groupedAirports: invertedValue.groupedAirports,
      });
    }
    return dispatch(new ChangeLanguageSuccess());
  }
  @Action(GetAnnouncements)
  public getAnnouncements({
    dispatch,
    patchState,
  }: StateContext<ResourcesStateModel>) {
    return this.announcementApiService.getAnnouncements().pipe(
      tap((announcements) =>
        patchState({
          announcements: sort(announcements, {
            field: 'showOrder',
            order: SortingOrder.Asc,
          }),
        })
      )
    );
  }
  @Action(GetAvailableAdditionalLuggage)
  public getAvailableAdditionalLuggage({ patchState }: StateContext<ResourcesStateModel>, { payload }: GetAvailableAdditionalLuggage) {
    return this.ordersApiService
      .getAvailableAdditionalLuggage(payload)
      .pipe(tap((availableLuggage) => patchState({ availableLuggage })));
  }
  private invert(
    airports: Airport[],
    countries: Country[],
    cities: City[],
    allowedDirections: AllowedDirection[]
  ) {
    const newAirports = airports.map((value) => ({
      ...value,
      name: value.systemName,
      systemName: value.name,
    }));
    const newCountries = countries.map((value) => ({
      ...value,
      name: value.systemName,
      systemName: value.name,
    }));
    const newCities = cities.map((value) => ({
      ...value,
      name: value.systemName,
      systemName: value.name,
    }));
    const groupedAirports = this.groupedAirportsFactory(
      newAirports,
      newCountries,
      newCities,
      allowedDirections
    );
    return { newAirports, newCountries, newCities, groupedAirports };
  }
  private groupedAirportsFactory(
    airports: Airport[],
    countries: Country[],
    cities: City[],
    allowedDirections: AllowedDirection[]
  ): AirportExtended[] {
    const citiesDic: Record<number, City> = {};
    cities.forEach((c) => (citiesDic[c.id] = c));

    const countriesDic: Record<number, Country> = {};
    countries.forEach((c) => (countriesDic[c.id] = c));

    let resultDic: Record<number, AirportExtended> = {};
    const result: AirportExtended[] = [];

    airports
      .filter((x) => !x.isDeleted)
      .forEach((airport) => {
        const city = citiesDic[airport.cityId];
        const country = countriesDic[city ? city.countryId : 0];

        const iata = airport.iata ?? '';
        const airportName = airport.name ?? '';
        const airportSysName = airport.systemName ?? '';
        const cityName = city ? city.name : '';
        const citySysName = city ? city.systemName : '';
        const countryName = country ? country.name : '';
        const countrySysName = country ? country.systemName : '';
        const value =
          `${iata} ${airportName} ${airportSysName} ${cityName} ${citySysName} ${countryName} ${countrySysName}`.toLowerCase();
        // .replace(/\s+/g, '')
        resultDic[airport.id] = <AirportExtended>{
          value,
          airport,
          city,
          country,
        };
      });

    for (let key in resultDic) {
      const value = resultDic[key];

      if (value.airport.parentId && value.airport.parentId !== 0) {
        value.parent = Object.assign({}, resultDic[value.airport.parentId!]);
        value.hasParentInSearchResult = true;
      }

      result.push(value);
    }

    return result
      .sort(sortAirportsFunc)
      .filter(
        (item) =>
          allowedDirections.findIndex(
            (el) =>
              el.cityFromId === item.city?.id || el.cityToId === item.city?.id
          ) !== -1
      );
  }
}
