import {RUS_ENG_DIC} from '@app-core/constants';
import {Airport, AirportExtended, City, Country} from '@app-core/entities';
import {DictionaryType} from '@app-core/models';

export const convertString = (str: string): string => {
  return str
    .split('')
    .map((c) => {
      const convertedChar = RUS_ENG_DIC[c];
      return convertedChar ? convertedChar : c;
    })
    .join('');
};
export const calcHasParent = (items: AirportExtended[]): AirportExtended[] => {
  const result: AirportExtended[] = [];
  const lookup: DictionaryType<AirportExtended> = {};
  //TODO чет как-то не очень
  items.forEach((item) => {
    lookup[item.airport.id] = {...item};
  });
  items.forEach((item) => {
    const { airport, city, country, parent } = item;

    if (parent !== undefined) {
      if (lookup[airport.parentId!] === undefined) {
        lookup[airport.id].hasParentInSearchResult = false;
        lookup[airport.id].label = labelFactoryIn(
          airport,
          city,
          country,
          false,
          false
        );
      } else {
        lookup[airport.id].hasParentInSearchResult = true;
        lookup[airport.id].label = labelFactoryIn(airport, city, country, false);
      }
    } else {
      lookup[airport.id].label = labelFactoryIn(airport, city, country, true);
    }
    result.push(lookup[airport.id]);
  });
  return result;
};

export function search(
  items: any[],
  searchTerm: string | number,
  key = 'value',
  comparison?: 'equal' | 'includes',
  nestedKey?: string
): any[] {
  return items.filter((item) => {
    if (comparison === 'equal') {
      return nestedKey
        ? item[key][nestedKey] === searchTerm
        : item[key] === searchTerm;
    } else {
      return nestedKey
        ? item[key][nestedKey].includes(searchTerm)
        : item[key].includes(searchTerm);
    }
  });
}
const labelFactoryIn = (
  airportIn: Airport,
  cityIn: City | undefined,
  countryIn: Country | undefined,
  parent: boolean,
  hasParent: boolean = true
): string => {
  const iata = airportIn.iata ?? '';
  const airportName = airportIn.name ?? '';
  const cityName = cityIn ? cityIn.name : '';
  const countryName = countryIn ? countryIn.name : '';

  let result = '';
  if (parent && cityIn !== undefined) {
    result = `${iata} ${cityName} ${countryName}`;
  } else if (!hasParent) {
    result = `${iata} ${airportName} ${cityName}`;
  } else {
    result = `${iata} ${airportName}`;
  }
  return result;
};
const makeString = (airport: AirportExtended) =>
  airport.parent
    ? airport.parent.airport.name + airport.airport.name
    : airport.airport.name;

export const sortAirportsFunc = (
  airport1: AirportExtended,
  airport2: AirportExtended
) => makeString(airport1).localeCompare(makeString(airport2));

export const labelFactory = (
  airportIn: Airport,
  cityIn: City | undefined,
  countryIn: Country | undefined,
  parent: boolean
): string => {
  const iata = airportIn.iata ?? '';
  const airportName = airportIn.name ?? '';
  const cityName = cityIn ? cityIn.name : '';
  const countryName = countryIn ? countryIn.name : '';

  return parent
    ? `${iata} ${cityName} ${countryName}`
    : `${iata} ${airportName}`;
};
